import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {  NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import ExamBlock from './ExamBlock';

export default function BatchExam() {
  const [AllExamList1, setAllExamList1] = React.useState({
    exam_data: []
  });
  const [reload, setReload] = React.useState(false)
  const [AllExam, setAllExam] = React.useState([]);
  const [MyExam, setMyExam] = React.useState([]);
  const [CompletedExam, setCompletedExam] = React.useState([]);
  const [NotFoundData, setNotFound] = React.useState(false)

  const fetchExam = (setAllExamList, perPage, slug) => {
    setReload(true)
    const id = store.getState().LoginState.userID
    NodePOSTApiAuth({
      userID: id,
      slug: slug
    }, "batches-details")
      .then((res) => {
        if (res.data.error)
          setNotFound(true)
        else {
          setAllExamList(res.data)
          setAllExam(res.data.exam_data)

          var arrCompleted = [];
          var arrMy = [];
          for (let i = 0; i < res.data.exam_data.length; i++) {
            var item = res.data.exam_data[i];
            if (res.data.result_data.filter(b => b.meta.exam_id_results == item.id).length !== 0) {
              arrCompleted.push(item)
              setCompletedExam(arrCompleted)
            }
            else {
              arrMy.push(item)
              setMyExam(arrMy)
            }
          }
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
      .finally(()=>{
        setReload(false)
      })
  }

  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {}, location.pathname.slice(slugs.batch_exam.length + 1))
    console.log(location.pathname.slice(slugs.batch_exam.length + 1))
  }, [])

  return (
    <div>
      {!reload ?
        <>
          {NotFoundData ?
            <NotFound /> :

            <div className="row">
              <div className="col-1"></div>
              <div className="col-10 form-view mt-3">
                <div className="row" style={{ margin: "10px" }}>
                  <h2>All Exams </h2>
                  <div style={{ margin: "10px", cursor: "pointer" }}>
                    <span onClick={() => { setAllExam(AllExamList1.exam_data) }}
                    > All Exam ({AllExamList1.exam_data.length}) | </span>
                    <span onClick={() => { setAllExam(MyExam) }}
                    >My Exam ({MyExam.length}) | </span>
                    <span onClick={() => { setAllExam(CompletedExam) }}
                    >Completed Exam ({CompletedExam.length}) | </span>
                  </div>
                  {AllExam.map((item, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                      <ExamBlock
                        item={item} examSlug={slugs.start_daily_exam} AllExamList1={AllExamList1}
                        fetchExam={fetchExam} setAllExamList1={setAllExamList1}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        </>
        :
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}