import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { apiUrl, MediaUpload } from '../../../../Constant/api';

export default function AudioUpload2(props) {
  const {
    ImageUrl,
    setImageUrl,
    PDfGroup,
    setPDFGroup,
    item,
    index,
    newUpload
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)
  const [AudioUrl, setAudioURL] = React.useState(item.audio_url)

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("2", file)
    // setMediaUploadType(file.type.split("/")[0])
    MediaUpload(file)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setAudioURL(data.source_url)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        if (!newUpload) {
          var pdf = PDfGroup;
          pdf = {
            ...pdf,
            [`item-${index}`]: {
              ...pdf[`item-${index}`],
              "audio_url": `${data.source_url}`,
            }
          }
          setPDFGroup(pdf)
        }
        else
          setImageUrl(data.source_url)
      })
      .catch(err => { console.log("error", err.message); })
      .finally(() => { })
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div>
        <div>
          {item.audio_url !== "" && AudioUrl !== "" ?
            <div>
              <div className="">
                <audio controls>
                  <source src={`${AudioUrl}`} type="audio/mpeg" />
                  <source src={`${AudioUrl}`} type="audio/mp3" />
                  <source src={`${AudioUrl}`} type="audio/wav" />
                  <source src={`${AudioUrl}`} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <a href={AudioUrl} target="_blank"
                className="bg-transparent border-0 Link"
              >
                &nbsp;
                {AudioUrl.replace(`${apiUrl}wp-content/uploads/`, "").slice(8)}
              </a>
              <span onClick={() => {
                setAudioURL("")

                if (!newUpload) {
                  var pdf = PDfGroup;
                  pdf = {
                    ...pdf,
                    [`item-${index}`]: {
                      ...pdf[`item-${index}`],
                      "audio_url": ``,
                    }
                  }
                  setPDFGroup(pdf)
                }
              }}
                className="bg-transparent border-0 "
              ><u>Clear</u></span>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0"
              >
                <div className="font-size-12" style={{ padding: "10px" }}>
                  {/* <i className="fa fa-headphone font-size-18"></i> */}
                  <img src="https://ubt25api.abmsoftnepal.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <div>Add</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="audio/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>

    </div>
  )
}