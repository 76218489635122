import React from "react";
import FormComponent from "./Components/Form";
import Database from '../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
export default function NewPackages() {
  const FetchData = Database.exam_packages;
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Package</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_exam_packages}>All Packages</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
      />
    </div>
  )
}