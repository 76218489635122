import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { MediaUpload, apiUrl } from '../../../../Constant/api';

export default function FileUploadChapters(props) {
  const {
    PDfGroup,
    setPDFGroup,
    ThisPDF,
    index, type
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)
  const [URL, setURL] = React.useState("")

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("2", file)
    // setMediaUploadType(file.type.split("/")[0])
    MediaUpload(file)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        console.log(data.source_url)
        // setImageUrl(data.source_url)
        setURL(data.source_url)
        var pdf = PDfGroup;
        pdf = {
          ...pdf,
          [`item-${index}`]: `${data.source_url}`
        }
        setPDFGroup(pdf)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch(err => { console.log("error", err.message); })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="">
        <div className="row">
          {/* {ThisPDF} */}
          {ThisPDF !== "" ?
            <div className="">
              {type == "audio" ?
                <audio controls>
                  {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                  <source src={`${ThisPDF}`} type="audio/mpeg" />
                  <source src={`${ThisPDF}`} type="audio/mp3" />
                  <source src={`${ThisPDF}`} type="audio/wav" />
                  <source src={`${ThisPDF}`} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
                :
                <a href={ThisPDF} target="_blank" className="Link">
                  {ThisPDF.replace(`${apiUrl}wp-content/uploads/`, "")}
                </a>
              }
              <button onClick={() => {
                var pdf = PDfGroup;
                pdf = {
                  ...pdf,
                  [`item-${index}`]: ""
                }
                setPDFGroup(pdf)
              }}
                className="bg-transparent border-0 p-1 "
              >

                <u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 "
              >
                <div style={{ padding: "3px 7px", border: "0.5px solid #999", borderRadius: "3px" }}>
                  {/* <i className="fa fa-file-pdf-o font-size-18"></i> */}
                  <div>Add</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        {type == "audio" ?
          <input type="file" accept="audio/*" name="image" id="file" ref={fileRefSingle}
            onChange={(event) => {
              if (event.target.files.length !== 0) {
                setLoading(true)
                MediaUploadSingleCall(event.target.files[0])
              }
            }}
          />
          :
          <input type="file" accept="file/*" name="image" id="file" ref={fileRefSingle}
            onChange={(event) => {
              if (event.target.files.length !== 0) {
                setLoading(true)
                MediaUploadSingleCall(event.target.files[0])
              }
            }}
          />
        }
      </div>
    </div>
  )
}