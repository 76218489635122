import React, { useEffect } from "react";
import { AdsGETApiAuth } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";

export default function StudentExamModal(props) {
  const {
    setStudentID,
    setStudentEmail,
    StudentID,
    setStudentPhoneNumber,
    setStudentUserName,
    setStudentModal

  } = props;
  const [reload, setReload] = React.useState(false)
  const [Projects1, setProjects1] = React.useState([])
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")

  const [UserArray, setUserArray] = React.useState([])
  const GetAllProjects = (params, slug) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setProjects1(res.data)
        setUserArray(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 100000,
      _fields: "id,slug,meta.user_phone_number,meta.user_id,meta.user_name,meta.user_email"
    }, slugs.db_slug_user_data)

  }, [])
  return (
    <div>
      <div className="row">
        <div style={{ width: "10px" }} />
        <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
          value={serachName} placeholder="Student Name"
          onChange={(event) => {
            setSearchName(event.target.value)
            setProjects1(UserArray.filter(a => a.meta.user_name.toLowerCase().includes(event.target.value.toLowerCase())))
          }}
        />
        <input className="col-4" style={{ margin: "1px 2px", padding: "3px 2px" }}
          value={serachEmail} placeholder="Student Email"
          onChange={(event) => {
            setSearchEmail(event.target.value)
            setProjects1(UserArray.filter(a => a.meta.user_email.toLowerCase().includes(event.target.value.toLowerCase())))
          }}
        />
        <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
          value={serachPhone} placeholder="Student Phone Number"
          onChange={(event) => {
            setSearchPhone(event.target.value)
            setProjects1(UserArray.filter(a => a.meta.user_phone_number.toLowerCase().includes(event.target.value.toLowerCase())))
          }}
        />
      </div>
      <table>
        <tbody>
          <tr>
            <td colSpan={6}
              onClick={() => {
                setStudentID("")
                setStudentEmail("")
                setStudentPhoneNumber("")
                setStudentUserName("")
                setStudentModal(false)
              }}>
              Clear
            </td>
          </tr>
          {Projects1.map((item, index) => (
            <tr key={index}
              onClick={() => {
                setStudentID(item.meta.user_id)
                setStudentPhoneNumber(item.meta.user_phone_number)
                setStudentEmail(item.meta.user_email)
                setStudentUserName(item.meta.user_name)
                setStudentModal(false)
              }}
            >
              <>
                <td>
                  {item.id == StudentID ?
                    <i className="fa fa-check-square-o" />
                    :
                    <i className="fa fa-square-o" />
                  }
                </td>
                {/* <td>{item.id}</td> */}
                <td>{item.meta.user_id}</td>
                <td>{item.meta.user_phone_number}</td>
                <td>{item.meta.user_name}</td>
                <td>{item.meta.user_email}</td>
                <td style={{ cursor: "pointer" }}

                >Choose</td>
              </>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}