


import React from 'react'
import { slugs } from '../../../Constant/slugs';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../CustomButtom';
import ImageUpload from '../../ImageUpload';
import { NodePOSTApi, NodePUTApi } from '../../../Constant/api';

export default function ExamBlock(props) {
  const { item, examSlug, AllExamList1, setAllExamList1, fetchExam } = props;
  const Login = useSelector((state) => state.LoginState);


  return (
    <div>
      <div style={{
        padding: "5px 0", margin: "5px 0px", border: "1.5px solid #bbb",
        borderRadius: "5px",
      }} className="row">
        <div style={{ width: "100%", }}>
          <div style={{ textAlign: "left", fontSize: "12px" }}>
            <div style={{ textAlign: "center" }}>
              <img
                src={item.meta.featured_image}
                style={{ width: "100px" }}
              />
            </div>
            <table>
              <tbody>
                <tr>
                  <td className="col-6">Name</td>
                  <td className="col-6"> {item.title.rendered}</td>
                </tr>
                <tr>
                  <td className="col-6">Exam Time</td>
                  <td className="col-6"> {item.meta.exam_time} min</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          {item.meta.available_unavailable == "Available" ?
            <div>
              <div>
                {AllExamList1.result_data.filter(b => b.meta.exam_id_results == item.id).length !== 0 ?
                  <div className="row">
                    <Link to={`${slugs.daily_exam_result}/${AllExamList1.result_data.filter(b => b.meta.exam_id_results == item.id)[0].slug}`}
                      style={{ width: "100%" }}>
                      <div className="sign-in-button-2" style={{ width: "100%",backgroundColor: "#000" }}>
                        View_Result
                      </div>
                    </Link>
                    {/* <div
                      style={{ width: "50%", }}>
                      <Link className=" Link" to={`${slugs.exam_payment}/${item.slug}`}>
                        <div className="sign-in-button-12" style={{ backgroundColor: "#000" }}>
                          Reset Exam
                        </div>
                      </Link>
                    </div> */}
                  </div>
                  :
                  <div>
                    <Link className=" Link" to={`${examSlug}/${item.slug}`}>
                      <div className="sign-in-button-2">
                        Start Exam
                      </div>
                    </Link>
                  </div>
                }
              </div>

            </div>
            :
            <div className="sign-in-button-12 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
              Unavailable
            </div>
          }
        </div>
      </div >


    </div >

  )
}